import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from "react-router-dom";
import App from "./App.jsx";

// Importing CSS files here
import './styles/jack-base-css.css';
import './styles/styles.css';
import './styles/OCRstyles.css';
import './styles/updatedCardStyles.css';
import './styles/ankaCoderCondensed.css';
import './styles/datalegreya.css';
import './styles/meyrin.css';

const domContainer = document.querySelector('#root');
const root = createRoot(domContainer);

root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
)