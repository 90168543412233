import React, {useState, useEffect} from 'react';

const buildDate = () => {
  const versionBuildDate = new Date();
  return (
    `${versionBuildDate.getMonth() + 1}/${versionBuildDate.getDate()}/${versionBuildDate.getFullYear()}`
  );
}

export const BuildDate = () => {
  const [curDate, setCurDate] = useState("");
  useEffect(() => {
    const getBuildDate = buildDate();
    setCurDate(getBuildDate);

  }, []);

  console.log("CUR DATE ", curDate);

  return (
    <div>
      <p>Version: 0.0.5</p>
      <p>Last Updated: { `${ curDate } `}</p>
    </div>
  )
}