import React, {useEffect, useState, Suspense, useCallback} from "react";
import CardColumn from "../Components/ColumnDataContainer";
import ThreeColumnGrid from "../Components/ThreeColumnWithMain";
import { Card } from "../Components/Card";
import { getCMSPieces } from "../main";

const TextBasedInfo = () => {
  return (
    <div>
      <p>Documents articles and other</p>
    </div>
  )
}


export const TextContentRequest = () => {
  let [results, setResults] = useState([]);
  let [beginFetch, setBeginFetch] = useState(0)

  useEffect( () => {
    if(beginFetch < 1){
      const fetchAirtable = async () => {
        try{
          const airtableResponse = await getCMSPieces()
          setResults(airtableResponse?.data);
        }catch(e){
          console.error(" Error Fetching Airtable links");
        }
      }
      
      fetchAirtable();
      setBeginFetch(beginFetch++);

    }
    return () => {
      setBeginFetch(0);
    }
  }, [])

  return (
    <ThreeColumnGrid>
      <div></div>
        <TextBasedInfo />
      <div></div>
    </ThreeColumnGrid>
  )
}

