import React from "react";
import ThreeColumnGrid from "./ThreeColumnWithMain";
import { Hamburger } from "../Icons";
import { BuildDate } from "./DateStamp";
import {
  atom,
  selector,
  useRecoilState,
  useRecoilValue,
} from 'recoil';
import {showNav} from "../state"

export default () => {

  const [open, setOpen] = useRecoilState(showNav);
  return (
    <>
      <ThreeColumnGrid style={{position: 'sticky', top: '0', borderBottom: '1px solid lightgrey', zIndex: 5}}>
        <div onClick = { () => setOpen(!open) } style={{display: "grid", placeItems: "center"}}>
          <Hamburger
            style={{
              padding: '1px',
              border: '1px solid black',
              borderRadius: '13%',
              boxShadow: '2px 2px 3px grey',
              width: '50%',
              height: '20px',
              margin: '10px',
              transition: 'transform 0.3s',
              cursor: 'pointer'
            }} 
          />
        </div> 
        <div>   
          <h1 className={"header"}>
            BTC Chokidar
          </h1>
        </div>
        <div className={"subheader"} >          
          <BuildDate />
        </div>
      </ThreeColumnGrid>
    </>

  )
}