import React from "react";
//import PropTypes from 'prop-types';

const ThreeColumnGrid = ({style, children, bgHex="#f9f9f9" }) => {
  const styles = {
    display: 'grid',
    gridTemplateColumns: "minmax(10px, 10%) 1fr minmax(10px, 10%)",   
    backgroundColor: bgHex,
    ...style
  };

  return <div style={styles}>{children}</div>;
};

export default ThreeColumnGrid;
