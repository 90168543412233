import React from "react";
import ThreeColumnGrid from "./ThreeColumnWithMain";


export const SubHeader = () => {
  return (
    <ThreeColumnGrid 
      style={{
        zIndex: '1', 
        position:"sticky", 
        top: 0
      }}
    >
      <div></div>
      <div>
        <p style={{
          textAlign:"center", 
          fontSize: "0.8em", 
          whiteSpace: 'normal', 
          wordBreak: 'keep-all',
          overflowWrap: 'normal'
        }}
        >
          “It is well enough that people of the nation do not understand our banking and monetary system
          <br/> for if they did, I believe there would be a revolution before tomorrow morning.”
        </p>
        <p style={{textAlign:"center", fontSize: "0.8em"}}> - Henry Ford </p>        
      </div>
      <div></div>
    </ThreeColumnGrid>
  )
}