import React, { useState } from "react";
import { RecoilRoot } from 'recoil';
import { YouTubeLinkRequest } from "./Integrations/YouTubeLinkRequest";
import { CMSContentRequest } from "./Integrations/CMSContentRequest";
import { TextContentRequest } from "./Integrations/TextContentRequest";
import Header from "./Components/Header";
import { SubHeader } from "./Components/SubHeader";
import RememberMe from "./Components/RememberMe";
import SideNavigationPanel from "./Components/SideNavigationPanel";
import { FormUpload } from './Components/FormUpload/formUpload';
import { NoPage } from "./Components/NoPage";
import { Routes, Route } from "react-router-dom";

export default () => {
  return (
    <RecoilRoot> 
      <Header />
      <SubHeader />
        <Routes>
          <Route path="/" element={<YouTubeLinkRequest/>} />        
          <Route path="texts" element={<TextContentRequest />} />
          <Route path="cms" element={<CMSContentRequest />} />
          <Route path="ocr" element={<FormUpload/>} />
          <Route path="*" element={<NoPage />} />
        </Routes>
      <SideNavigationPanel className="navBar show" />
      <RememberMe />
    </RecoilRoot>
  );
}
