import React, { useState, useRef, Suspense } from 'react';
import ThreeColumnGrid from "../ThreeColumnWithMain";

const onError = () => {
  return (
    <p> Some Error </p>
  )
}

const onSuccess = () => {
  return (
    <p> Some Success </p>
  )
}

const onUploadProgress = () => {
  return (
    <p> Progress Started</p>
  )
}

const onUploadStart = () => {
  return (
    <p>Upload Started </p>
  )
}

const copyToClipboard = () => {
  const element = document.getElementById('scroll-target');
  if (element) {
    const text = element.innerText;
    navigator.clipboard.writeText(text).then(() => {
      alert('Copied to clipboard');
    }).catch((err) => {
      alert('Failed to copy: ', err);
    });
  }
};

export const FormUpload = () => {

  const [clicked, setClicked] = useState(false);
  const [file, setFile] = useState();
  const [text, setText] = useState();
  const [filePath, setFilePath] = useState();
  const [discoveredText, setDiscoveredText] = useState("");
  const [processingError, setProcessingError] = useState();

  const handleChange = (e) => {
    setFile(e.target.files[0]);
  };

  const [previewImage, setPreviewImage] = useState(null);

  const handleImagePreview = (event) => {
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onloadend = () => {
        setPreviewImage(reader.result);
      };

      reader.readAsDataURL(file);
    }
  };

  const submit = (e) => {
    e.preventDefault();
    const uploadForm = document.getElementById('uploadForm');
    const devURL = "http://127.0.0.1:5001/btcv2-9892c/us-central1/ocr";
    const URL = "https://us-central1-btcv2-9892c.cloudfunctions.net/ocr";
    const formData = new FormData(uploadForm);
    setClicked(true);

    fetch(URL, {
      method: 'POST',
      body: formData
    })
    .then(response => {
      return response.json();
    }) // Assuming the response will be JSON
    .then(data => {
        // Handle success (e.g., show a message, redirect, etc.)
        const tempText = Object.values(data);
        const tempFileName = Object.entries(data);
        console.log(" eventPhase: ", e.eventPhase)
        setDiscoveredText(tempText[0])
        setFilePath(tempFileName[0])        
    })
    .then( () => {
      const element = document.getElementById('scroll-target');
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    })
    .catch((error) => {
        console.error('Error:', error);
        setProcessingError(`ERROR: ${error?.message ?? error}`);
    });
  }

  //In order to use the SDK, you need to provide it with a few configuration parameters. 
  //The configuration parameters can be applied directly to the IKImage component or using 
  //an IKContext component.

  return (
    <>
      <ThreeColumnGrid style={{minHeight: "10vh"}}>
        <div></div>
        <h3>Image Text Recognition</h3>
        <div></div>
      </ThreeColumnGrid>
      <ThreeColumnGrid style={{maxHeight: "10%"}}>            
        <div></div>
        <div>
          <p>Here you can upload an image, like a screenshot, that contains text.</p>
          <p>The program will extract your text so you can copy and paste it wherever you'd like</p>
          <span style={{margin: "5px"}}>
            <strong>Privacy</strong>
          </span>
          <p>No images or text you upload here are stored to a database</p> 
        </div>
        <div></div>   
        </ThreeColumnGrid>
        <ThreeColumnGrid >
          <div></div>
          <div style={{margin: "5px"}}>
            <form method="post" encType="multipart/form-data" id="uploadForm">
              <input onChange={handleImagePreview} filename={text} type='file' id="avatar" name="avatar" accept="image/png, image/jpeg" />
              <input type="submit" value="Upload Image" name="submit" onClick={submit}/>        
            </form>
          </div>
          <div></div>
        </ThreeColumnGrid>
        <ThreeColumnGrid style={{minHeight: "5vh"}}>
          <div></div>
          <h3>Image Preview</h3>
          <div></div>
        </ThreeColumnGrid>
        <ThreeColumnGrid>
          <div></div>
          <div className='response'>
              { 
                previewImage 
                ? (
                  <div style={{display: 'grid', placeItems: 'center', margin: "10px"}}>
                    <img src={previewImage} alt="Preview" style={{ maxWidth: '60%'}} />
                  </div>
                )
                : null
              }
          </div>
          <div></div>
        </ThreeColumnGrid>
        <ThreeColumnGrid>
          <div></div>
            <div className="text-from-image">
              <h2>Result: </h2>
              {
                !discoveredText 
                ? <h3>
                    {clicked ? "Processing..." : "This process takes about 90 seconds"}
                  </h3>
                : null
              }
              <textarea 
                id="scroll-target" 
                rows={15} 
                style={{display: "flex", width: "100%", marginBottom: "12px", padding: "12px"}} 
                value={discoveredText || processingError || ""}>
                  
                </textarea>
            </div>
          </ThreeColumnGrid>
          <ThreeColumnGrid style={{margin: '10px'}}>
            <div></div>
              <div>
                <button onClick={copyToClipboard}>
                  Copy to Clipboard
                </button>
              </div>
            <div></div>
          </ThreeColumnGrid>
          <ThreeColumnGrid style={{margin: '10px'}}>
            <div></div>
            <div>
              <p style={{fontSize:"0.8em"}}>
                I would like to thank the team at 
              <a href="https://github.com/tesseract-ocr/tesseract">
                Tesseract
              </a>
                for making this project possible.
              </p>
            </div>
            <div></div>
          </ThreeColumnGrid>
    </>
  )
  }
